import React from "react";
import { Grid, Typography, Divider, Checkbox } from "@mui/material";
import { colors } from "../../lib/theme";

const ExclusiveAddons = ({ formData, handleChange }) => {
  const userCountry = localStorage.getItem("userCountry");

  return (
    <>
      <Typography color={colors.navy} variant="h5" gutterBottom sx={{ mt: 6 }}>
        Exclusive Addons
      </Typography>
      <Divider sx={{ mb: 2 }} />
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={6}>
          <Typography variant="body1" color={colors.navy}>
            Personalized Vinyl Record
          </Typography>
          <Typography variant="caption" color={colors.navy}>
            Limited Edition
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body1" color={colors.navy}>
            {userCountry === "US" ? "+$125" : "+£125"}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Checkbox
            name="personalizedVinyl"
            checked={formData.personalizedVinyl}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default ExclusiveAddons;
